    <template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">

                <v-btn v-show="$vuetify.breakpoint.xsOnly"  color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-card flat class="background_color_transparent">
                        <v-card-text class="py-0">
                            <v-row class="pt-5">
                                <v-col cols="12" sm="8" class="title_menu mt-1">
                                    {{  $t('deal.self') }}{{ process ? (': «' + process.name + '»') : '' }} {{  $t($route.params.type+'s') }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <TabsNav :items="items"/>
                                </v-col>
                            </v-row>



                        </v-card-text>
                    </v-card>


                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-2">

                        <v-col cols="12">
                            Участники
                            <v-btn
                                color="primary"
                                icon
                                @click="dialogParticipant = true"
                            >
                                <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="py-1" v-for="item in BusinessProcessParticipantItems" :key="item.id">
                            <v-list class="my-0 py-0">
                                <template>
                                    <v-list-item class="mx-1">
                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{ 'settingsIcon' }}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0 title_subtitle">
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon>
                                                <v-icon color="grey lighten-1" small
                                                        @click="deleteParticipant(item)"
                                                >mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-list-item-action>

                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-col>


                        <v-col cols="12">
                            Департаменты
                            <v-btn
                                color="primary"
                                icon
                                @click="dialogDepartment=true"
                            >
                                <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="py-1" v-for="item in BusinessProcessDepartmentItems" :key="item.id">
                            <v-list class="my-0 py-0">
                                <template>
                                    <v-list-item class="mx-2">
                                        <v-list-item-content class="py-0 title_subtitle">
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                         </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon>
                                                <v-icon color="grey lighten-1" small
                                                @click="deleteDepartment(item)"
                                                >mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-list-item-action>

                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-col>


                    </v-card-text>
                </v-card>
            </div>
        </div>

        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogParticipant"
        >

            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('add_participant') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="dialogParticipantClose()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-5">
                            <v-col  cols="12">
                                <ValidationProvider ref="admin" rules="min:1" v-slot="{ errors, valid }">
                                    <UserSelection
                                        v-model="participants"
                                        :valuel="participants"
                                        id="responsible2"
                                        :label="$t('participants')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable
                                        multiple
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 pt-10 pb-7">
                        <v-btn @click="addParticipant" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                            {{ $t('add') }}
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            class="ma-1 button_cancel"
                            plain
                            @click="dialogParticipantClose()"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>

                    </v-card-actions>

                </v-card>
            </ValidationObserver>

        </v-dialog>

        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogDepartment"
        >

            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('add_department') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="dialogDepartmentsClose()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-5">
                            <v-col class="py-0" cols="12">
                                <v-autocomplete
                                    v-model="departments"
                                    :disabled="isUpdating"
                                    :items="departmentItems"
                                    chips
                                    :label="$t('departments.head')"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                    outlined
                                    solo
                                    flat
                                    dense
                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                    color="primary"
                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                    clearable
                                >
                                    <template v-slot:selection="data">
                                        <v-chip
                                            v-bind="data.name"
                                            :input-value="data.selected"
                                            close
                                            @click="data.select"
                                            @click:close="removeDepartment(data.item)"
                                            class="input_chip"
                                        >

                                            <v-avatar left>
                                                <v-icon >mdi-circle</v-icon>
                                            </v-avatar>
                                            {{ data.item.name }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="typeof data.item !== 'object'">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-avatar>
                                                <v-icon :color="data.item.color">mdi-circle</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 pt-10 pb-7">
                        <v-btn @click="addDepartments" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                            {{ $t('add') }}
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            class="ma-1 button_cancel"
                            plain
                            @click="dialogDepartmentsClose()"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>

                    </v-card-actions>

                </v-card>
            </ValidationObserver>

        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import UserSelection from "@/components/Form/UserSelection.vue";
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";

export default {
    name: 'DealStepSettings',
    components: {
        SettingMenu,
        TabsNav,
        UserSelection,
        ValidationObserver,
        ValidationProvider
    },
    props: {
        businessProcessId: {
            default: null
        }
    },
    inject: ['forceRerender'],
    data() {
        return {
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            DepartmentItems: [],
            errors: {},
            filter_show: false,
            departmentItems: [],
            BusinessProcessParticipantItems:[],
            BusinessProcessDepartmentItems:[],
            items: [
                {
                    title: 'menu_deal_step',
                    icon: 'icon-user',
                    route: {
                        name: 'deal_step_show',
                        params: {
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                },
                {
                    title: 'participants',
                    icon: 'icon-clients',
                    route: {
                        name: 'deal_step_participants',
                        params: {
                            type: 'participant',
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                },
                {
                    title: 'auditors',
                    icon: 'icon-clients',
                    route: {
                        name: 'deal_step_auditors',
                        params: {
                            type: 'auditor',
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                },

                {
                    title: 'moderators',
                    icon: 'icon-clients',
                    route: {
                        name: 'deal_step_moderators',
                        params: {
                            type: 'moderator',
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                }
            ],
            dialogParticipant: false,
            dialogDepartment: false,
            participants:[],
            departments:[],
            isUpdating: false,
            businessProcessItems: []
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang','BusinessProcessId']),
        languages() {
            return this.listLanguages
        },
        process() {
            return this.businessProcessItems.find(item => item.id === parseInt(this.$route.params.business_process))
        }
    },

    async mounted() {
       await this.loadData();
    },
    watch: {
        '$route'() {
            this.loadData();
        }
    },
    methods: {
        async loadData(){
            this.language = this.languages[this.tab]
            await this.getBusinessProcesses();
            await this.getBusinessProcessDepartment()
            await this.getBusinessProcessParticipant()
            await this.getDepartments()
        },
        handleFilterShow(action){
            this.filter_show = action;
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        async getBusinessProcesses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.businessProcessItems = res.body.data
                })
                .catch(err => {
                    this.businessProcessItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getBusinessProcessDepartment() {
            this.loading = true
            let params = {}
            params.business_process = this.$route.params.business_process
            params.type = this.$route.params.type
            await this.$http
                .get("admin/business_process_department", {
                    params: params,
                })
                .then(res => {
                    this.BusinessProcessDepartmentItems = res.body.data
                })
                .catch(err => {
                    this.BusinessProcessDepartmentItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getBusinessProcessParticipant() {
            this.loading = true
            let params = {}
            params.business_process = this.$route.params.business_process
            params.type = this.$route.params.type
            await this.$http
                .get("admin/business_process_participant", {
                    params: params,
                })
                .then(res => {
                    this.BusinessProcessParticipantItems = res.body.data
                })
                .catch(err => {
                    this.BusinessProcessParticipantItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getDepartments2() {
            this.loading = true
            await this.$http
                .get("admin/department")
                .then(res => {
                    this.departmentItems = res.body.data
                })
                .catch(err => {
                    this.departmentItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getDepartments() {
            let params = {};

            await this.$http
                .get(`admin/department`, {
                    params: params,
                })
                .then(res => {
                    let deps = res.body.data;

                    deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
                    deps = buildListArrayFromTreeArray(deps);

                    for (let i in deps) {
                        let department = deps[i];
                        department.name = (' . '.repeat((department.depth ?? 1))) + department.name
                    }



                    this.departmentItems = deps;
                })
                .catch(() => {
                    this.departmentItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_departments'))
                });
        },
        dialogParticipantClose(){
            this.dialogParticipant = false;
            this.participants = [];
        },
        async addParticipant() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }


            if (this.$route.params.business_process) {
                formData.append('business_process_id', this.$route.params.business_process)
            }

            if (this.$route.params.type) {
                formData.append('type', this.$route.params.type)
            }




            if (this.participants && this.participants.length > 0) {
                for (let i in this.participants) {
                    if (this.participants[i].id !== undefined && this.participants[i].id > 0) {
                        formData.append(`admins[${i}]`, this.participants[i].id)
                    }
                }
            }


            // Add
            await this.$http
                .post('admin/business_process_participant', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_step_has_been_updated'))
                    this.dialogParticipantClose()
                    this.getBusinessProcessParticipant()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_step_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        dialogDepartmentsClose(){
            this.dialogDepartment = false;
            this.departments = [];
        },
        async addDepartments() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.departments.length > 0) {
                for (let i = 0; i < this.departments.length; i++) {
                    formData.append(`departments[${i}]`, this.departments[i]);
                }
            }

            if (this.$route.params.business_process) {
                formData.append('business_process_id', this.$route.params.business_process)
            }

            if (this.$route.params.type) {
                formData.append('type', this.$route.params.type)
            }


                // Add
            await this.$http
                .post('admin/business_process_department', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_step_has_been_updated'))
                    this.dialogDepartmentsClose()
                    this.getBusinessProcessDepartment()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_step_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async deleteDepartment(item) {
            if (confirm(this.$t('delete'))) {
                var _this = this;
                this.loading = true
                await this.$http
                    .delete(`admin/business_process_department/${item.business_process_departments_id}`)
                    .then(res => {
                        this.getBusinessProcessDepartment()
                    })
                    .catch(err => {
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async deleteParticipant(item) {
            if (confirm(this.$t('delete'))) {
                var _this = this;
                this.loading = true
                await this.$http
                    .delete(`admin/business_process_participant/${item.business_process_participant_id}`)
                    .then(res => {
                        this.getBusinessProcessParticipant()
                    })
                    .catch(err => {
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
}
</script>
